import Store from '../../store/store';

let ACTIONS;

const TYPE = {
  default: 0,
  trip: 1,
};

const DEFAULT = {
  searchFilter: {
    init: false,
    label: '',
    value: '',
    isActiveBookTrip: false,
    isSearchByInn: false,
  },
  type: TYPE.default,
  byQuery: false,
  queryString: '',
};

const createQueryString = () => '';

const reducer = (action, state) => {
  const newState = { ...state };

  switch (action.type) {
    case ACTIONS.FILTER.SETSEARCHFILTER: {
      newState.searchFilter.init = true;
      newState.searchFilter.label = action.label;
      newState.searchFilter.value = action.value;
      newState.type = TYPE.default;
      newState.queryString = createQueryString(newState);
      break;
    }

    case ACTIONS.FILTER.SETSEARCHTRAVELLERSFILTER: {
      newState.searchFilter.init = true;
      newState.searchFilter.label = action.label;
      newState.searchFilter.value = action.value;
      newState.type = TYPE.default;
      newState.queryString = '';
      break;
    }

    case ACTIONS.FILTER.CANCELSEARCHFILTER: {
      newState.searchFilter = {
        init: false,
        label: '',
        value: '',
      };
      newState.type = TYPE.default;
      newState.queryString = '';
      break;
    }

    case ACTIONS.FILTER.CANCELSEARCHTRAVELLERFILTER: {
      newState.searchFilter = {
        init: false,
        label: '',
        value: '',
      };
      newState.type = TYPE.default;
      newState.queryString = '';
      break;
    }

    case ACTIONS.FILTER.CHANGESEARCHFILTERVALUE: {
      newState.searchFilter.value = action.value;
      newState.queryString = createQueryString(newState);
      break;
    }

    case ACTIONS.FILTER.CHANGESEARCHTRAVELLERFILTERVALUE: {
      newState.searchFilter.value = action.value;
      newState.queryString = '';
      break;
    }

    case ACTIONS.FILTER.SEARCHORDERSFILTER: {
      newState.searchFilter.init = true;
      newState.searchFilter.label = action.label;
      newState.searchFilter.value = action.value;
      newState.searchFilter.isActiveBookTrip = action.valueActive;
      newState.type = TYPE.trip;
      newState.byQuery = false;
      newState.queryString = '';
      break;
    }

    case ACTIONS.FILTER.CHANGEISACTIVEBOOKTRIPS: {
      newState.searchFilter.isActiveBookTrip = action.valueActive;
      newState.queryString = createQueryString(newState);
      break;
    }

    case ACTIONS.FILTER.CHANGEISSEARCHBYINN: {
      return {
        ...newState,
        searchFilter: {
          ...newState.searchFilter,
          isSearchByInn: action.isInnOnly,
        },
        queryString: '',
      };
    }

    case ACTIONS.FILTER.SETSEARCHFILTERBYQUERY: {
      newState.searchFilter.init = true;
      newState.searchFilter.value = decodeURIComponent(action.payload.query);
      newState.searchFilter.label = action.payload.label;
      newState.searchFilter.isActiveBookTrip = action.payload.active;
      newState.type = TYPE.trip;
      newState.byQuery = true;
      newState.queryString = createQueryString(newState);
      break;
    }
  }

  return newState;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Filter', reducer, DEFAULT);

  const createStore = () => new Store('Filter', reducer, DEFAULT);

  return createStore();
}
