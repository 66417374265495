import Store from '../../store/store';
import defaultState from './defaultState';

let ACTIONS;

const DEFAULT = defaultState;

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.STOREINIT:
      return DEFAULT;

    case ACTIONS.DOCUMENTS_CONSTRUCTOR.SET_OPZ: {
      return {
        ...state,
        isOPZ: action.payload,
      };
    }

    case ACTIONS.DOCUMENTS_CONSTRUCTOR.SET_DOCUMENT_DIVIDE_TYPES: {
      return {
        ...state,
        Acts: {
          ...state.Acts,
          DocumentDivideTypes: action.payload,
        },
      };
    }

    case ACTIONS.DOCUMENTS_CONSTRUCTOR.REMOVE_DOCUMENT: {
      const { id, tabId } = action.payload;
      const { ServiceOperations } = state[tabId].Settings.find(item => item.Id === id);
      const removedOperations = ServiceOperations.map(({ ServiceType, ServiceOperationNames }) => ({
        ServiceType,
        ServiceOperationNames: ServiceOperationNames.map(Operation => ({ ...Operation, added: false })),
      }));

      return {
        ...state,
        [tabId]: {
          ...state[tabId],
          Settings: state[tabId].Settings.filter(item => item.Id !== id),
          NotAddedOperations: [...state[tabId].NotAddedOperations, ...removedOperations],
        },
      };
    }

    case ACTIONS.DOCUMENTS_CONSTRUCTOR.SAVE_DOCUMENT: {
      const { capitalizedTabId, settings, notAddedOperations } = action.payload;
      const existingDocumentIndex = state[capitalizedTabId].Settings.findIndex(({ Id }) => Id === settings.Id);
      const updatedSettings = [...state[capitalizedTabId].Settings];

      if (existingDocumentIndex !== -1) {
        const existingDocument = state[capitalizedTabId].Settings[existingDocumentIndex];
        const updatedDocument = { ...existingDocument, ...settings };
        updatedSettings[existingDocumentIndex] = updatedDocument;
      } else {
        updatedSettings.push(settings);
      }

      return {
        ...state,
        [capitalizedTabId]: {
          ...state[capitalizedTabId],
          NotAddedOperations: notAddedOperations,
          Settings: updatedSettings,
        },
      };
    }

    case ACTIONS.DOCUMENTS_CONSTRUCTOR.SET_DIVIDE_INVOICES: {
      return {
        ...state,
        Invoices: {
          ...state.Invoices,
          DivideAsInTheActs: action.payload,
        },
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('DocumentsConstructor', reducer, DEFAULT);

  const createStore = () => new Store('DocumentsConstructor', reducer, DEFAULT);

  return createStore();
}
