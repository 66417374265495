let ACTIONS;

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.EMPLOYEE.ALL_EMPLOYEE:
      return {
        ...state,
        allEmployee: action.res,
      };
    case ACTIONS.EMPLOYEE.UPDATE:
      return {
        ...state,
        employee: action.res,
      };
    case ACTIONS.EMPLOYEE.GET_TRIPS:
      return {
        ...state,
        trips: action.res,
      };
    case ACTIONS.EMPLOYEE.GET_PROJECTS:
      return {
        ...state,
        projects: action.res,
      };
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Employee', reducer);
}
