// временная заглушка
const defaultState = {
  isOPZ: false,
  Acts: {
    DocumentDivideTypes: 'Без разделения',
    NotAddedOperations: [],
    Settings: [
      {
        Id: 1,
        ClosingSettingsId: null,
        Name: 'Акт 1',
        CompanyDocumentPlaceHolders: null,
        SettingVAT: 'Отображать столбец НДС',
        SeparateColumn: 'Не отображать дополнительные колонки',
        FillColumns: [],
        ServiceOperations: [
          {
            ServiceType: 'Авиа',
            ServiceOperationNames: [
              { name: 'А/б с НДС', added: true, nameContents: [] },
              { name: 'А/б без НДС', added: true, nameContents: [] },
              { name: 'Возврат а/б с НДС', added: true, nameContents: [] },
              { name: 'Возврат а/б без НДС', added: true, nameContents: [] },
              { name: 'Изменение а/б с НДС', added: true, nameContents: [] },
              { name: 'Изменение а/б без НДС', added: true, nameContents: [] },
              { name: 'Сборы для а/б', added: true, nameContents: [] },
              { name: 'Штрафы для а/б', added: true, nameContents: [] },
            ],
          },
          {
            ServiceType: 'Ж/Д',
            ServiceOperationNames: [
              { name: 'Ж/д с НДС', added: true, nameContents: [] },
              { name: 'Ж/д без НДС', added: true, nameContents: [] },
              { name: 'Возврат ж/д с НДС', added: true, nameContents: [] },
              { name: 'Возврат ж/д без НДС', added: true, nameContents: [] },
              { name: 'Сборы для ж/д', added: true, nameContents: [] },
              { name: 'Штрафы для ж/д', added: true, nameContents: [] },
            ],
          },
          {
            ServiceType: 'Гостиницы',
            ServiceOperationNames: [
              { name: 'Гостиницы с НДС', added: true, nameContents: [] },
              { name: 'Гостиницы без НДС', added: true, nameContents: [] },
              { name: 'Штрафы для гостиниц', added: true, nameContents: [] },
            ],
          },
          {
            ServiceType: 'Справка о перелетах',
            ServiceOperationNames: [
              { name: 'Покупка справки', added: true, nameContents: [] },
              { name: 'Отмена справки', added: true, nameContents: [] },
              { name: 'Тариф по справкам', added: true, nameContents: [] },
            ],
          },
          {
            ServiceType: 'Вип-залы',
            ServiceOperationNames: [
              { name: 'Вип-залы с НДС', added: true, nameContents: [] },
              { name: 'Вип-залы без НДС', added: true, nameContents: [] },
              { name: 'Штрафы для вип-залов', added: true, nameContents: [] },
            ],
          },
          {
            ServiceType: 'Трансферы',
            ServiceOperationNames: [
              { name: 'Трансферы', added: true, nameContents: [] },
              { name: 'Штрафы для трансферов', added: true, nameContents: [] },
            ],
          },
          {
            ServiceType: 'Кастомные услуги',
            ServiceOperationNames: [
              { name: 'Кастомы с НДС', added: true, nameContents: [] },
              { name: 'Кастомы без НДС', added: true, nameContents: [] },
              { name: 'Штрафы для кастомов', added: true, nameContents: [] },
            ],
          },
          {
            ServiceType: 'Аэроэкспрессы',
            ServiceOperationNames: [
              { name: 'Аэроэкспресс', added: true, nameContents: [] },
              { name: 'Сборы для аэроэкспрессов', added: true, nameContents: [] },
              { name: 'Возврат Аэроэкспресса', added: true, nameContents: [] },
              { name: 'Штрафы для аэроэкспрессов', added: true, nameContents: [] },
            ],
          },
          {
            ServiceType: 'Другие услуги',
            ServiceOperationNames: [
              { name: 'Комиссия за транзакцию', added: true, nameContents: [] },
            ],
          },
        ],
      },
      {
        Id: 2,
        ClosingSettingsId: null,
        Name: 'Акт taxi',
        CompanyDocumentPlaceHolders: null,
        SettingVAT: 'Отображать столбец НДС',
        SeparateColumn: 'Не отображать дополнительные колонки',
        FillColumns: [],
        ServiceOperations: [
          {
            ServiceType: 'Такси',
            ServiceOperationNames: [
              { name: 'Такси с НДС', added: true, nameContents: [] },
            ],
          },
        ],
      },
      {
        Id: 3,
        ClosingSettingsId: null,
        Name: 'Акт subscription',
        CompanyDocumentPlaceHolders: null,
        SettingVAT: 'Отображать столбец НДС',
        SeparateColumn: 'Не отображать дополнительные колонки',
        FillColumns: [],
        ServiceOperations: [
          {
            ServiceType: 'Абонентская плата',
            ServiceOperationNames: [
              { name: 'Абонентская плата', added: true, nameContents: [] },
            ],
          },
        ],
      },
      {
        Id: 4,
        ClosingSettingsId: null,
        Name: 'Акт air/additional',
        CompanyDocumentPlaceHolders: null,
        SettingVAT: 'Отображать столбец НДС',
        SeparateColumn: 'Не отображать дополнительные колонки',
        FillColumns: [],
        ServiceOperations: [
          {
            ServiceType: 'Доп. услуги для авиа',
            ServiceOperationNames: [
              { name: 'Доп. услуга к а/б без НДС', added: true, nameContents: [] },
              { name: 'Доп. услуга к а/б с НДС', added: true, nameContents: [] },
              { name: 'Возврат доп. услуги к а/б с НДС', added: true, nameContents: [] },
              { name: 'Возврат доп. услуги к а/б без НДС', added: true, nameContents: [] },
              { name: 'Сборы для доп. услуги к а/б', added: true, nameContents: [] },
              { name: 'Штраф для доп. услуги к а/б', added: true, nameContents: [] },
            ],
          },
        ],
      },
      {
        Id: 5,
        ClosingSettingsId: null,
        Name: 'Акт mice',
        CompanyDocumentPlaceHolders: null,
        SettingVAT: 'Отображать столбец НДС',
        SeparateColumn: 'Не отображать дополнительные колонки',
        FillColumns: [],
        ServiceOperations: [
          {
            ServiceType: 'MICE',
            ServiceOperationNames: [
              { name: 'Мероприятие с НДС', added: true, nameContents: [] },
              { name: 'Мероприятие без НДС', added: true, nameContents: [] },
              { name: 'Штрафы для мероприятия', added: true, nameContents: [] },
            ],
          },
        ],
      },
    ],
  },
  Invoices: {
    DivideAsInTheActs: false,
    NotAddedOperations: [],
    Settings: [
      {
        Id: 1,
        ClosingSettingsId: null,
        Name: 'Счет-фактура для гостиниц',
        ServiceOperations: [
          {
            ServiceType: 'Гостиницы',
            ServiceOperationNames: [
              { name: 'Гостиницы с НДС', added: true },
            ],
          },
          {
            ServiceType: 'Вип-залы',
            ServiceOperationNames: [
              { name: 'Вип-залы с НДС', added: true },
            ],
          },
          {
            ServiceType: 'Кастомные услуги',
            ServiceOperationNames: [
              { name: 'Кастомы с НДС', added: true },
            ],
          },
        ],
      },
      {
        Id: 2,
        ClosingSettingsId: null,
        Name: 'Счет-фактура для такси',
        ServiceOperations: [
          {
            ServiceType: 'Такси',
            ServiceOperationNames: [
              { name: 'Такси с НДС', added: true },
            ],
          },
        ],
      },
      {
        Id: 3,
        ClosingSettingsId: null,
        Name: 'Счет-фактура для mice',
        ServiceOperations: [
          {
            ServiceType: 'MICE',
            ServiceOperationNames: [
              { name: 'Мероприятие с НДС', added: true },
            ],
          },
        ],
      },
    ],
  },
  RegistrySettings: {},
};

export default defaultState;
