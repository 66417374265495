let ACTIONS;

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.EMPLOYEES.UPDATE:
      return {
        ...state,
        employees: action.res,
      };
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Employees', reducer);
}
